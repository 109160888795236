import * as dayjs from 'dayjs';

export class Experience {
  id: number | string;
  businessId: number | string;
  description: string;
  job_title: string;
  location: string;
  name: string;
  start_date: string;
  end_date: string;
  is_current_job: boolean;

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    this.id = obj.id || '';
    this.businessId = obj.businessId || '';
    this.description = obj.job_description || '';

    this.job_title = obj.job_title || '';
    this.location =
      (obj && obj.business && obj.business.city + ', ' + obj.business.state) ||
      '';
    this.name = (obj && obj.business && obj.business.brand_name) || '';
    this.start_date = obj.start_date || null;
    this.end_date = obj.end_date || null;
  }

  get time(): string {
    const start = dayjs(this.start_date);
    const end = this.end_date ? dayjs(this.end_date) : dayjs();
    const isEnded = this.end_date !== null;
    const diffMonth = end.diff(start, 'month') % 12;
    const diffYear = end.diff(start, 'year');
    const diffText =
      (diffYear ? diffYear + ' Year' + (diffYear > 1 ? 's ' : ' ') : '') +
      diffMonth +
      ' Month' +
      (diffMonth > 1 ? 's' : '');

    return (
      start.format('MMM YYYY') +
      ' - ' +
      (isEnded ? end.format('MMM YYYY') : 'Present') +
      ' | ' +
      diffText
    );
  }

  formatDate(date) {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }
}
