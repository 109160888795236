import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@auth0/auth0-angular';
import { LoginComponent } from './login/login.component';

const routes: Routes = [
  { path: "", redirectTo: "/sign-up", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  {
    path: 'sign-up',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./sign-up/sign-up.module').then((m) => m.SignUpModule),
  },
  { path: "**", redirectTo: "/" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
