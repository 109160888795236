<div class="min-h-full flex bg-white">
  <div class="flex-1 flex flex-col py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 max-w-2xl w-100">
    <div class="mx-auto w-full max-w-lg lg:w-96 mt-16">
      <!-- <div>
        <img class="h-12 w-auto" src="assets/img/logo-v1.png" alt="Workflow" />
      </div> -->
      <!-- <h3 class="mt-4 font-extrabold text-gray-900 break-normal !text-[30px]">
        Sign in to your account
      </h3> -->

      <div class="mt-8">
        <div class="mt-6" *ngIf="showLoginButton && !afterLoginError">
          <button class="focus:outline-none" (click)="auth.loginWithRedirect()">Log in with <strong>Auth0</strong></button>
        </div>
        <div class="mt-6">          
          <button type="button" class="focus:outline-none" *ngIf="afterLoginError" (click)="userService.logout()">Try again</button>
        </div>
      </div>
    </div>
  </div>
  <div class="relative w-100 w-full">
    <!-- <img class="absolute inset-0 h-full w-100 object-cover object-top" src="assets/img/img-login.png" alt="" /> -->
  </div>
</div>
