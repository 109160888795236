import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

import { AuthService } from '@auth0/auth0-angular';
import { catchError, finalize } from 'rxjs';
import { UserService } from '../Services/user.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  private returnUrl: string;
  public afterLoginError = false;

  private isLoggedOut: string;
  public showLoginButton = false;
  public performingAfterLogin = false;

  constructor(
    public userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
    public auth: AuthService
  ) {}

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'sign-up';
    this.isLoggedOut = this.route.snapshot.queryParams['logout'];

    this.auth.user$.subscribe((profile) => {
      if (profile) {
        if (this.userService.isLoggedIn.value || this.performingAfterLogin) {
          return;
        }
        this.performingAfterLogin = true;
        // localStorage.setItem('currentUserEmail', profile.email)

        if(profile.name) {
          const currentUserPhone = profile.name
          const countryCodeLength = currentUserPhone.length - 10
          const countryCode = currentUserPhone.substring(0, countryCodeLength)
          const phoneNumber = currentUserPhone.substring(countryCodeLength, currentUserPhone.length)
          localStorage.setItem('currentUserPhone', phoneNumber)
          localStorage.setItem('currentUserCountryCode', countryCode)
        } else {
          localStorage.setItem('currentUserPhone', profile.phone)
        }

        console.log('Current User', profile)
        localStorage.setItem('currentUser', JSON.stringify(profile))

        this.auth.idTokenClaims$.subscribe((profile) => {
          if(profile){
            localStorage.setItem('authToken',profile.__raw);
          }
        });
      }
    });
    
    this.auth.isAuthenticated$.subscribe((loggedIn) => {
      // console.log(loggedIn)
      // console.log(this.isLoggedOut)
      if (!loggedIn && this.isLoggedOut !== 'true') {
        // this.auth.loginWithRedirect({appState: { target: 'AuthRedirectId'}}); 
        this.auth.loginWithRedirect();
      } else {
        this.showLoginButton = this.isLoggedOut === 'true';
      }
    });
  }
}
