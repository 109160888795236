import { Injectable, OnDestroy } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Subject } from 'rxjs';
import { SnackBarComponent } from '../snack-bar/snack-bar.component';

export interface ISnackBarData {
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class SnackBarService implements OnDestroy {
  public static defaultSimpleMessageDuration = 4500;

  public static defaultInfoPanelClass = '!bg-gray-500';
  public static defaultErrorPanelClass = '!bg-red-500';
  public static defaultSuccessPanelClass = '!bg-green-500';

  private messageQueue: MatSnackBarConfig<ISnackBarData>[] = [];
  private isInstanceVisible = false;
  private unsubscribe = new Subject<void>();

  constructor(private snackBar: MatSnackBar) { }

  public ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  public success(message: string, config?: MatSnackBarConfig<ISnackBarData>): void {
    this.addMessage('success', message, config);
  }

  public info(message: string, config?: MatSnackBarConfig<ISnackBarData>): void {
    this.addMessage('info', message, config);
  }

  public error(message: string, config?: MatSnackBarConfig<ISnackBarData>): void {
    this.addMessage('error', message, config);
  }

  private addMessage(type: 'success' | 'info' | 'error', message: string, config?: MatSnackBarConfig<ISnackBarData>): void {
    if (!config) {
      config = new MatSnackBarConfig<ISnackBarData>();
      config.duration = type === 'error' ? 10000 : 3000;
      config.verticalPosition = 'bottom';
      config.horizontalPosition = 'center';

      switch (type) {
        case 'error': config.panelClass = [SnackBarService.defaultErrorPanelClass]; break;
        case 'info': config.panelClass = [SnackBarService.defaultInfoPanelClass]; break;
        case 'success': config.panelClass = [SnackBarService.defaultSuccessPanelClass]; break;
      }
    }

    if (type === 'error' && this.isTraceBack(message)) {
      message = 'A server error has occurred. Please contact a developer for further assistance.';
    }

    config.data = { message };

    this.messageQueue.push(config);

    if (!this.isInstanceVisible) {
      this.showNext();
    }
  }

  private isTraceBack(message: string): boolean {
    if (!message) {
      return false;
    }

    return message.toLowerCase().includes('traceback');
  }

  private showNext() {
    if (this.messageQueue.length !== 0) {
      const message = this.messageQueue.shift();
      this.isInstanceVisible = true;

      this.snackBar.openFromComponent(SnackBarComponent, message)
        .afterDismissed().subscribe(() => {
          this.isInstanceVisible = false;
          this.showNext();
        });
    }
  }
}
