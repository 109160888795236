import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTextComponent } from './header-text/header-text.component';
import { LoadingComponent } from './loading/loading.component';
import { LogoComponent } from './logo/logo.component';
import { MarketingPointComponent } from './marketing-point/marketing-point.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { UserImageComponent } from './user-image/user-image.component';

import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { PhoneInputComponent } from './phone-input/phone-input.component';
import { CountryInputComponent } from './country-input/country-input.component'
import { FlagLocationPipe } from './country-input/flag-location.pipe';


@NgModule({
  declarations: [
    HeaderTextComponent,
    LoadingComponent,
    LogoComponent,
    MarketingPointComponent,
    SnackBarComponent,
    UserImageComponent,
    PhoneInputComponent,
    CountryInputComponent,
    FlagLocationPipe
  ],
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  exports: [
    HeaderTextComponent,
    LoadingComponent,
    LogoComponent,
    MarketingPointComponent,
    SnackBarComponent,
    UserImageComponent,
    PhoneInputComponent,
    CountryInputComponent,
    MatSnackBarModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    NgxMaskDirective,
    NgxMaskPipe,
    FlagLocationPipe
  ]
})
export class SharedModule { }
