import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Business } from '../Models/business.model';
import { User } from '../Models/user.model';

export interface LoginRes {
  user: User;
  business: Business[];
}

@Injectable({
  providedIn: 'root',
})
export class UserDataService {
  public static loginURL = `${environment.api}user/invite-login`;
  public static afterAuth0LoginForSignupURL = `${environment.api}user/afterInviteLogin`;
  public static afterAuth0LoginForSignupPhoneFirstURL = `${environment.api}admin/afterAuth0LoginForSignupPhoneFirst`;

  public static getFindByInvitationIdURL(invitationId: number) {
    return `${environment.api}invitation/find/${invitationId}`;
  }
  public static getFindByEmailURL(email: string) {
    return `${environment.api}user/find/email`;
  }

  constructor(private httpClient: HttpClient) {}

  findUserByEmail(email: string): Observable<any> {
    const body = { email }
    return this.httpClient
      .post<any>(`${environment.api}user/find/email`, body)
      .pipe(
        map((response) => {
          return response;
        })
      );
  }

  findUserByPhone(phone: string): Observable<any> {
    const body = { phone }
    // console.log('Body', body)
    return this.httpClient
      .post<any>(`${environment.api}user/find/phone`, body)
      .pipe(
        map((response) => {
          // console.log('Response', response)
          return response;
        })
      );
  }

  public findInvitationById(invitationId: number): Observable<any> {
    return this.httpClient.get(
      UserDataService.getFindByInvitationIdURL(invitationId)
    );
  }

  public login(email: string, password: string) {
    return this.httpClient.post<LoginRes>(UserDataService.loginURL, {
      email: email,
      password: password,
    });
  }

  afterAuth0LoginForSignup(profile: any) {
    return this.httpClient.post<LoginRes>(
      UserDataService.afterAuth0LoginForSignupURL,
      profile
    );
  }

  afterAuth0LoginForSignupPhoneFirst(profile: any) {
    return this.httpClient.post<LoginRes>(
      UserDataService.afterAuth0LoginForSignupPhoneFirstURL,
      profile
    );
  }

  editUserProfile(data: any, userId: number) {
    console.log('Using this id to edit profile', userId)
    const body = data
    return this.httpClient.put<any>(
      `${environment.api}user/${userId}/edit`,
      body
    );
  }

}
