import { environment } from "../../environments/environment";

export class Business {
  id: number;
  employees_list: [];
  businesss_list: [];
  address_1: string;
  address_2: string;
  brand_internal_id: string;
  brand_name: string;
  city: string;
  email: string;
  google_place_id: string;
  location: { _latitude: string; _longitude: string };
  location_latitude: string;
  location_longitude: string;
  logo_image_ref: string;
  //ext_image_ref: string;
  phone: string;
  rating_value: number;
  ratings_received: number;
  state: string;
  type: string;
  website: string;
  zip: string;
  admin_label: string;
  team_label: string;
  allow_employees_selectem_team: boolean;
  show_venmo_username: boolean;
  disable_user_identity_validation: boolean;
  roles_list: [];
  user_business_role: {
    role_id: number;
  };

  constructor(obj?: any) {
    if (!obj) {
      return;
    }
    this.id = obj.id || "";
    this.employees_list = obj.employees_list || [];
    this.businesss_list = obj.businesss_list || [];
    this.address_1 = obj.address_1 || "";
    this.address_2 = obj.address_2 || "";
    this.brand_internal_id = obj.brand_internal_id || "";
    this.brand_name = obj.brand_name || "";
    this.city = obj.city || "";
    this.email = obj.email || "";
    this.google_place_id = obj.google_place_id || "";
    this.location_latitude = obj.location_latitude || "0";
    this.location_longitude = obj.location_longitude || "0";
    
    this.logo_image_ref = obj.logo_image_ref || '';
    
    this.phone = obj.phone || "";
    this.rating_value = obj.rating_value || 0;
    this.ratings_received = obj.ratings_received || 0;
    this.state = obj.state || "";
    this.type = obj.type || "";
    this.website = obj.website || "";
    this.zip = obj.zip || "";
    this.admin_label = obj.admin_label || "";
    this.team_label = obj.team_label || "";
    this.allow_employees_selectem_team =
      obj.allow_employees_selectem_team || false;
    this.show_venmo_username = obj.show_venmo_username;
    this.disable_user_identity_validation =
      obj.disable_user_identity_validation;
    this.roles_list = obj.roles_list || [];
    this.user_business_role = obj.user_business_role || {};
  }

  get businessIdentifier() {
    return {
      business_id: this.id /*,
            employees_list: this.employees_list,
            businesss_list: this.businesss_list*/,
    };
  }

  get address() {
    return this.address_1 + ", " + this.city + ", " + this.state;
  }

  get rating() {
    return Math.round(this.rating_value * 10) / 10;
  }
  get ext_image_ref() {
    if (this.logo_image_ref.indexOf(environment.awsStorageUrl) > -1) {
      return this.logo_image_ref;
    } else if(this.logo_image_ref) {
      return environment.awsStorageUrl + this.logo_image_ref;
    }
    return "";
  }
}
