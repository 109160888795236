import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from '@angular/common/http';

import { catchError, Observable } from 'rxjs';
import { SnackBarService } from '../shared/services/snack-bar.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private snackBar: SnackBarService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = {};

    if (!request.headers.get('Accept')) {
      headers['Accept'] = 'application/json';
    }

    if (!request.headers.get('x-api-key')) {
      headers['x-api-key'] = environment.xApiKey;
    }
    // console.log(localStorage.getItem('authToken'));
    
    headers['Access-Control-Allow-Origin'] = '*';
    headers['Authorization'] = 'Bearer '+localStorage.getItem('authToken');

    const clonedRequest = request.clone({
      setHeaders: headers,
      url: request.url
    });
    return next.handle(clonedRequest).pipe(
      catchError(response => {
        this.snackBar.error(response?.error?.error_message || 'Something went wrong. Please try again later.');

        throw response;
      }));
  }
}
