import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Business } from '../Models/business.model';
import { User } from '../Models/user.model';
import { map, distinctUntilChanged, filter, catchError } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';
import { UserDataService } from './user-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnInit {
  public currentUser: BehaviorSubject<User> = new BehaviorSubject<User>(
    new User()
  );
  public isLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public shouldShowPasswordReset: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public selectedBusiness: BehaviorSubject<Business> =
    new BehaviorSubject<Business>(new Business());

  public currentRoute

  constructor(
    public auth: AuthService,
    private userDataService: UserDataService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    const storedUser = localStorage.getItem('currentUser');
    if (!storedUser) return;
    const currentUser = new User(JSON.parse(storedUser));

    this.currentUser.next(currentUser);

    // const storedBusiness = localStorage.getItem('selectedBusiness');
    // if (!storedBusiness) {
    //   this.selectedBusiness.next(currentUser.business[0]);
    // } else {
    //   let strdBusiness = undefined;
    //   try {
    //     strdBusiness = JSON.parse(storedBusiness);
    //   } catch (e) {}
    //   this.selectedBusiness.next(new Business(strdBusiness));
    // }
    // this.selectedBusiness
    //   .pipe(
    //     distinctUntilChanged(),
    //     filter((business) => {
    //       return !!business.id;
    //     })
    //   )
    //   .subscribe((business: Business) => {
    //     localStorage.setItem('selectedBusiness', JSON.stringify(business));
    //   });
    this.isLoggedIn.next(true);
  }

  ngOnInit(): void {
      this.route.url.subscribe(([url]) => {
        this.currentRoute = url.path
      })
  }

  private resolveLogin(result: any) {
    // console.log(result)
    if (!(result.user_settings)) {
      throw result;
    }
    const user = new User({
      ...result.user_settings,
      // business: result.user_settings.business,
    });

    localStorage.setItem('token', result.token);

    this.currentUser.next(user);
    // console.log('Resolve Login User', user)
    localStorage.setItem('currentUser', JSON.stringify(user));
    // localStorage.setItem('currentUserEmail', user.email)
    // localStorage.setItem('selectedBusiness', JSON.stringify(user.business[0]));
    // this.selectedBusiness.next(user.business[0]);
    this.isLoggedIn.next(true);

    // if (
    //   this.selectedBusiness.value &&
    //   this.selectedBusiness.value.user_business_role &&
    //   this.selectedBusiness.value.user_business_role.role_id >= 0
    // ) {
    //   if (this.selectedBusiness.value.user_business_role.role_id <= 2) {
    //     this.isSuperAdmin.next(true);
    //   }

    //   if (this.selectedBusiness.value.user_business_role.role_id == 0) {
    //     this.isSupervisor.next(true);
    //   }
    // }
    // console.log(localStorage)
    return user;
  }

  login(email: string, password: string) {
    return this.userDataService.login(email, password).pipe(
      map((result) => this.resolveLogin(result)),
      map((user) => {
        this.shouldShowPasswordReset.next(true);
        return user;
      })
    );
  }

  afterAuth0LoginForSignup(profile: any) {
    return this.userDataService.afterAuth0LoginForSignup(profile).pipe(
      map((result) => this.resolveLogin(result)),
      map((user) => {
        this.shouldShowPasswordReset.next(true);
        return user;
      }),
      catchError((err) => {
        // console.log('afterAuth0Login error', err);
        this.router.navigateByUrl(this.currentRoute);
        throw err;
      })
    );
  }

  afterAuth0LoginForSignupPhoneFirst(profile: any) {
    return this.userDataService.afterAuth0LoginForSignupPhoneFirst(profile).pipe(
      map((result) => this.resolveLogin(result)),
      map((user) => {
        this.shouldShowPasswordReset.next(true);
        return user;
      }),
      catchError((err) => {
        // console.log('afterAuth0Login error', err);
        this.router.navigateByUrl(this.currentRoute);
        throw err;
      })
    );
  }

  logout() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("selectedBusiness");
    localStorage.removeItem("token");

    this.auth.logout({
      logoutParams: {
        returnTo: environment.auth0RedirectUrl + "?logout=true"
      }
    });
  }
}
