<div class="flex items-center">
    <span class="grow pr-4 font-pnBold text-white">{{ data.message }}</span>

    <button type="button" class="focus:outline-none hover:!text-gray-300 text-white" (click)="snackBarRef.dismiss()">
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 13L13 1M1 1L13 13" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round" />
        </svg>
    </button>
</div>
