import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{

  constructor(public auth: AuthService){

  }
  ngOnInit(){
    this.auth.idTokenClaims$.subscribe((profile) => {
      if(profile){
        localStorage.setItem('authToken',profile.__raw);
      }
    });
  }
  title = 'signup';
}
